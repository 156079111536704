//import EmblaCarousel from 'embla-carousel'
import stickybits from 'stickybits'
import flatpickr from 'flatpickr'
import { Dutch } from 'flatpickr/dist/l10n/nl.js'
import Headroom from 'headroom.js'
import Plyr from 'plyr'
import Glide, { Controls, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'
import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'hammerjs'
import Chocolat from 'chocolat'
import swipeDetect from 'swipe-detect';
import '../css/src.css';

// Date picker
flatpickr('.inline_picker', {
  inline: true,
  altInput: '#inline_picked_date',
  locale: Dutch,
})

// Check screen size
var x = window.matchMedia('(min-width: 1024px)')
screenSize(x) // Call listener function at run time
x.addListener(screenSize) // Attach listener function on state changes

function screenSize (x) {
  if (x.matches) {

    // Make menu sticky
    var mainContent = document.getElementsByTagName('main')[0]
    var distanceToTop = 0
    var topBar = document.querySelector('.topbar')
    var topBarHeight = topBar.clientHeight

    window.addEventListener('scroll', function () {
      distanceToTop = mainContent.getBoundingClientRect().top

      // Make topbar sticky if topbar is out of viewport
      if (distanceToTop <= 0) {
        topBar.classList.add('sticky')
        // Give body some padding to disable jumping of content
        document.body.style.paddingTop = topBarHeight + 'px'
      } else {
        topBar.classList.remove('sticky')
        document.body.style.paddingTop = '0px'
      }
    })

    // Headroom
    var headroomElement = document.querySelector('.topbar')
    var headroomOptions = {
      // vertical offset in px before element is first unpinned
      offset: topBarHeight+100,
    }
    // construct an instance of Headroom, passing the element
    var headroom = new Headroom(headroomElement, headroomOptions)
    // initialise
    headroom.init();

    // Make sidebar sticky if sidebar is present and if screen size > 1024px
    var isStickySidebar = document.getElementsByClassName('sidebar')

    if (isStickySidebar.length > 0) {
      stickybits('.sidebar__inner',{
        stickyBitStickyOffset: 20,
        customVerticalPosition: true,
        useStickyClasses: false,
        verticalPosition: 'top',
      });
    }

    // Make sidebar sticky if sidebar is present and if screen size > 1024px
    var isStickyProductDetail = document.getElementsByClassName('product__detail');

    if (isStickyProductDetail.length > 0) {
      stickybits('.product__detail',{
        stickyBitStickyOffset: 20,
        customVerticalPosition: true,
        useStickyClasses: false,
        verticalPosition: 'top',
      });
    }
  }
}

//PLYR setup
const players = Array.from(document.querySelectorAll('.plyrPlayer')).map(p => new Plyr(p));

//home slider setup
let homeSliders = document.querySelectorAll('.glide.homeslider');

for (let i = 0; i < homeSliders.length; i++) {
  let glide = new Glide(homeSliders[i], {
      autoplay: 5000,
      animationDuration: 800,
      hoverpause: true,
      gap: 0
    });

  glide.mount({ Controls, Autoplay, Swipe });
}

let homeSlidersVideo = document.querySelectorAll('.glide.homeslider-video');

for (let i = 0; i < homeSlidersVideo.length; i++) {
  let glide = new Glide(homeSlidersVideo[i], {
      autoplay: false,
      animationDuration: 800,
      hoverpause: true,
      gap: 0
    });

    glide.mount({ Controls, Autoplay, Swipe });
}

//Content sliders setup
let contentSliders = document.querySelectorAll('.glide.contentGlide');

for (let i = 0; i < contentSliders.length; i++) {
  let glide = new Glide(contentSliders[i]);

  glide.mount({ Controls });
}

//Collection block sliders setup
let collectionSliders = document.querySelectorAll('.glide.collection');
for (let i = 0; i < collectionSliders.length; i++) {

  let gap = -120;
  if (collectionSliders[i].classList.contains('nogap')) {
    gap = 0;
  }

  let glide = new Glide(collectionSliders[i], {
    perView: 1,
    gap: gap,
  });

  glide.mount( { Swipe });
}

let blockCollectionSliders = document.querySelectorAll('.glide.blockCollection');

for (let i = 0; i < blockCollectionSliders.length; i++) {
  let glide = new Glide(blockCollectionSliders[i], {
    perView: 1.2,
    gap: 20,
  });

  glide.mount( { Swipe });
}

function listenForSwipe() {
  var TARGET_DOM_NODE = document.querySelector('.chocolat-img');
  swipeDetect(TARGET_DOM_NODE, function(e) { swipeChocolat(e); }, 20);
}

const apiChoc = Chocolat(document.querySelectorAll('.popup-image'), {
  loop: true,
  imageSize: 'contain'
}).api
const hammertime = null;
const apiChocMob = Chocolat(document.querySelectorAll('.popup-image-mobile'), {
  imageSize: 'contain',
  loop: true,
  allowZoom: false,
  afterImageLoad: function () {
    listenForSwipe();
  }
}).api

function swipeChocolat(direction) {
  if (direction == 'left') {
    apiChocMob.prev();
  }
  if (direction == 'right') {
    apiChocMob.next();
  }
}

// Toggle function
function toggler (p1, p2) {
  document.querySelectorAll(p1).forEach(item => {
    item.addEventListener('click', window.toggle.bind(p2));
  })
}

window.toggle = function(event) {
  var select = this;
  if(typeof(this) != 'string') {
    select = '.selection';
  }
  var element = document.querySelector(select)
  element.classList.toggle('open')
  var body = document.querySelector('body')
  body.classList.toggle('sidebar--open')
}

// Toggle menu
toggler('.nav-toggle', '#menu--mobile.nav')

// Toggle filter menu
toggler('.selection-toggle', '.selection')

// Toggle selection
toggler('.show-filters', '.sidebar.filter')

toggler('.opening-hours-toggle', '#storeHoursPopup')

// On click functions
document.body.onclick = function (e) {
  var clickedEl = window.event ? event.srcElement : e.target
  while (clickedEl != null) {
    // Opem submenu mobile
    if (clickedEl.className &&
      clickedEl.className.indexOf('has-sub') !== -1) {
      clickedEl.nextElementSibling.classList.toggle('open')
      clickedEl.classList.toggle('open')
      return
    }

    // Close submenu mobile
    if (clickedEl.className &&
      clickedEl.className.indexOf('level-back') !== -1) {
      document.querySelector('.submenu.open').className = 'submenu'
      return
    }

    clickedEl = clickedEl.parentNode
  }
}

document.querySelector('.bodyOverlay').addEventListener('click', function () {
  var openElements = document.querySelectorAll('#menu--mobile.nav,.selection,.sidebar.filter,#storeHoursPopup');
  for(var i = 0; i < openElements.length; i++) {
    var openElement = openElements[i];
    openElement.classList.remove('open');
  }
  var body = document.querySelector('body');
  body.classList.remove('sidebar--open');
});

// Listen for all clicks on the document
document.addEventListener('click', function () {

  var fullClickElm = event.target.closest('.fullClick');
  if (fullClickElm != null) {
    // Bail if it's a link
    if (!event.target.tagName == 'A') return;

    let linkElm = fullClickElm.getElementsByTagName('A').length > 0 ? fullClickElm.getElementsByTagName('A')[0] : null;
    if(linkElm != null) {
      var target = linkElm.getAttribute('target');
      var url = linkElm.getAttribute('href');
      var text = linkElm.innerText;
      if(target == '_blank') {
        window.open(url);
      } else {
        pushToDataLayer(text,url);
        window.location = url;
      }
    }

  } else if (event.target.classList.contains('filter__toggle')) {
    // Bail if it's not a .nav-link
    //if (!event.target.classList.contains('filter__toggle')) return;

    // Toggle the active class
    event.target.classList.toggle('open')

    var filterMenu = event.target.nextSibling;
    if(filterMenu && filterMenu.classList.contains('filter__menu')) {
      var hasVerticalScrollbar = filterMenu.scrollHeight > filterMenu.clientHeight;
      if(hasVerticalScrollbar) {
        filterMenu.classList.add('hasScroll');
      }
    }

    // Get all nav links
    var links = document.querySelectorAll('.filter__toggle')

    // Loop through each link
    for (var i = 0; i < links.length; i++) {
      // If the link is the one clicked, skip it
      if (links[i] === event.target) continue

      // Remove the .active class
      links[i].classList.remove('open')
    }
  }
  if(event.target.classList.contains('chocolat-top') || event.target.classList.contains('chocolat-image-canvas')) {
    apiChoc.close();
    apiChocMob.close();
  }
}, false)

// Hide social sidebar
window.addEventListener("scroll", function (event) {
  var scroll = this.scrollY;
  var socialSideBar = document.getElementById("social-sidebar");

  if(socialSideBar != null) {
    if (scroll > 200) {
      socialSideBar.classList.add('hide');
    } else {
      socialSideBar.classList.remove('hide');
    }
  }
});

var newsletterForm = document.getElementById('newsletterForm');
if(newsletterForm) {
  newsletterForm.addEventListener("submit", function(e) {
    e.preventDefault();
    var emailInput = document.getElementById('newsletterEmail');
    var data = {};
    data['email'] = emailInput.value;
    data[csrfTokenName] = csrfTokenValue;
    ajax.get('/actions/collection-module/form/subscribe-newsletter', data, function() {
      var newsletterForm = document.getElementById('newsletterForm');
      var newsletterMessage = document.getElementById('newsletterSuccesMessage');
      newsletterForm.classList.add('hidden');
      newsletterMessage.classList.remove('hidden');
      return false;
    });
  });
}



function pushToDataLayer(event, value) {
  var page = window.location.pathname;
  var dataObject = {
    'event': event,
    'category': 'click',
    'label': value,
    'currentpage': page
  };
  if(typeof dataLayer != 'undefined'){
    dataLayer.push(dataObject);
  }
}

var ajax = {};
ajax.x = function () {
  if (typeof XMLHttpRequest !== 'undefined') {
    return new XMLHttpRequest();
  }
  var versions = [
    "MSXML2.XmlHttp.6.0",
    "MSXML2.XmlHttp.5.0",
    "MSXML2.XmlHttp.4.0",
    "MSXML2.XmlHttp.3.0",
    "MSXML2.XmlHttp.2.0",
    "Microsoft.XmlHttp"
  ];

  var xhr;
  for (var i = 0; i < versions.length; i++) {
    try {
      xhr = new ActiveXObject(versions[i]);
      break;
    } catch (e) {
    }
  }
  return xhr;
};

ajax.send = function (url, callback, method, data, async) {
  if (async === undefined) {
    async = true;
  }
  var x = ajax.x();
  x.open(method, url, async);
  x.onreadystatechange = function () {
    if (x.readyState == 4) {
      callback(x.responseText)
    }
  };
  if (method == 'POST') {
    x.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  }
  x.send(data)
};

ajax.get = function (url, data, callback, async) {
  var query = [];
  for (var key in data) {
    query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
  }
  ajax.send(url + (query.length ? '?' + query.join('&') : ''), callback, 'GET', null, async)
};

ajax.post = function (url, data, callback, async) {
  var query = [];
  for (var key in data) {
    query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
  }
  ajax.send(url, callback, 'POST', query.join('&'), async)
};